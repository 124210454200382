import React from 'react'
import teacher from '../images/teacher.jpg'
import { Link } from 'react-router-dom'

export default function Teacher() {
  return (
    <div className='w-9/12 text-gray-600 md:w-11/12 m-auto xl:text-xl md:text-lg'>
      <div className='flex lg:flex-col m-8 '>
        <img src={teacher} className='w-80 xl:w-64 lg:max-h-56 mr-8 rounded-2xl' alt='teacher'/>
        <ol className='mt-4'>
          <li>ПІБ: Мигоренко Олександр Георгійович</li>
          <li>Освіта: Вища</li>
          <li>Місце роботи: Мистецька школа</li>
          <li>Посада: Викладач класу гітари</li>
          <li>Досвід викладацької діяльності: С 90-х років минулого століття</li>
          <li><a className='text-sky-600 hover:text-sky-400  focus:text-violet-300'  href='https://www.facebook.com/alekmig' target='_blank' rel='noreferrer'>Сторінка у Фейсбуці</a></li>
        </ol>
      </div>
        <p className='text-right mr-80 md:mr-0'><Link to={"/"} className=' text-amber-600 hover:text-amber-400'>Назад</Link></p>
        <p></p>
    </div>
  )
}
