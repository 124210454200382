import React from "react";
import { BrowserRouter, Routes, Route} from "react-router-dom"
import Home from './components/Home';
import Teacher from './components/Teacher';


import './App.css';

function App() {
  return (

    <BrowserRouter>
      {/* <Link to="/">Home</Link>
      <Link to="/teacher">About</Link> */}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/teacher' element={<Teacher />} />
      </Routes>
    </BrowserRouter>


  );
}
export default App;

