import React from 'react'
import Header from './Header'
import Separator from './Separator'
import Section from './Section'
import Contacts from './Contacts'
import Curriculum from './Curriculum'
import SectionGradient from './SectionGradient'
import { Analytics } from '@vercel/analytics/react'

export default function Home() {
  return (
    <div className="App sm:text-xl text-gray-600 ">
        <Header />
        <Separator />
        <Section>
          <Curriculum />
        </Section>
        <SectionGradient>
          <Separator />
          <Contacts />
        </SectionGradient>
        <Analytics />
      </div>
  )
}
